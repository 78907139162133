import React from "react"
import News from "../../blocks/news"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "lemajic_transfers_to_gent" }) {
    ...NewsInfo
  }
}`

export default class NewsText extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Центральный нападающий РФШ (Рига, Латвия), Дарко Лемаич, игрок из Сербии 🇷🇸, вчера официально перешел в бельгийский клуб JPL KAA Gent 🇧🇪.</p>
          <p>Трансфер Лемаича в «Гент» стал одним из крупнейших в латвийском футболе за последние время.</p>
          <p>Напомним, что Дарко перешел в ФК «Рига» из ФК «Индия» в июле 2017 года. В сезоне 2017/2018 он стал лучшим бомбардиром чемпионата Латвии в составе ФК «Рига», а в сезоне 2018-2019 перешел в футбольный клуб РФШ и в том же сезоне вновь стал лучшим бомбардиром чемпионата Латвии.</p>

        <p>Все трансферы Дарко Лемаича в Латвию были организованы и предоставлены спортивным агентством Catenaccio Sports Agency. Мы желаем ему удачи и больших достижений в его новом клубе.</p>
        </div>
      </div>
    )
  }
}